<template>
   <div v-if="item && Object.keys(item).length"
          class="menu-item " :class="{'menu-item_opened': menuItemOpened, 'menu-item_active': parseInt($route.params.id) === item.id}"
        >
          <div class="menu-item__header" v-if="!edit">
              <div class="menu-item__name" v-if="!isDocument" @click="menuItemOpened = !menuItemOpened">
                  <div class="menu-item__arrow" v-if="item.children.length && !isDocument">
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </div>
                  <v-icon v-if="depth > 0" class="mr-1">mdi-folder-outline</v-icon>
                  <div>{{item.title}}</div>
              </div>
              <div class="menu-item__name menu-item__document " v-else>
                  <v-icon v-if="depth > 0" class="mr-1">mdi-file-document-outline</v-icon>
                  <router-link :to="{name: 'HelpDocument', params: {id: item.id}}">{{item.title}}</router-link>
              </div>
              <div class="project__tools" v-if="availableTools && this.$vuetify.breakpoint.lgAndUp">
                  <div class="project__menu" @click="openTools()">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </div>
                  <Tools 
                  v-if="tools"
                  v-click-outside="hide"
                  :availableTools="availableTools"
                  :item="item"
                  :depth="depth"
                  :closeTools="closeTools"
                  @add-document="newDocument()"
                  @add-section="newSection()"
                  @deleteAction="showConfirm()"
                  @rename="rename(item.title)"
                  />
              </div>
          </div>
          <v-form class="mb-4" @submit.prevent="changeProjectName(name, item.id)">
            <v-text-field
                  outlined
                  append-icon="mdi-check"
                  label="Название раздела"
                  v-click-outside="hideInput"
                  type="text"
                  v-model="name"
                  v-if="edit"
                  :rules="[Rules.notEmpty]"
                  @click:append="changeProjectName(name, item.id)"
                  hide-details="true">
              </v-text-field>
              <v-btn type="submit" class="d-none"></v-btn>
          </v-form>
          <v-form class="mb-4" @submit.prevent="document ? addNewDocument(newSectionName)  : addNewSection(newSectionName)">
              <v-text-field
                  outlined
                  append-icon="mdi-check"
                  label="Название документа"
                  type="text"
                  v-model="newSectionName"
                  v-if="document !== null"
                  :rules="[Rules.notEmpty]"
                  @click:append="document ? addNewDocument(newSectionName)  : addNewSection(newSectionName)"
                  hide-details="true">
              </v-text-field>
              <v-btn type="submit" class="d-none"></v-btn>
          </v-form>
          <div class="menu-item__list">
            <MenuItem @setActive="menuItemOpened = true; $emit('setActive')" :active="active" :depth="depth+1" v-for="item in item.children" :item="item" :key="item.id"/>
          </div>
          <Dialog :title="item.title" v-if="deleteConfirm" @dialogState="confirmDelete"/>
    </div>
</template>

<script>
import MenuItem from '@/components/help/MenuItem'
import Tools from '@/components/help/Tools'
import { mapActions, mapGetters } from 'vuex';
import Dialog from '@/components/help/Dialog.vue';
export default {
  name: "MenuItem",
  props: {
    item: Object,
    depth: Number,
    active: Boolean
  },
  data: (vm) => ({
    name: vm.item.title,
    newSectionName: '',
    tools: false,
    edit: false,
    document: null,
    menuItemOpened: false,
    deleteConfirm: false,
    loading: false,

  }),
  components: {
    MenuItem,
    Tools,
    Dialog
  },
  computed: {
    ...mapGetters(['User', 'Rules']),
    availableTools() {
      if(this.User.is_superuser) {
        if(this.isDocument) {
           return ["rename", "delete"]
        }
        return  ["rename", "delete", "folder", "document"]
      }
      return false
    },
    isDocument() {
      return Object.keys(this.item.data).length > 0
    }
  },
  methods: {
    ...mapActions(['addPage', 'changePage', 'deletePage']),
    hide(e = 1) {
      if(!e) return
      this.tools = false;
    },
    showConfirm() {
      this.deleteConfirm = true
    },
    closeTools(){
      this.tools = false;
    },
    openTools() {
      this.tools = true;
    },
    hideInput(e = 1) {
      if(!e) return
      this.edit = false;
      this.document = null;
    },
    rename(text) {
      this.edit = true;
      this.hide();
    },
    newDocument() {
      this.document = true;
      this.hide();
    },
    newSection() {
      this.document = false;
      this.hide();
    },
    async addNewSection(name) {
      if (!name) return;
      let form_data = new FormData();
      form_data.append("title", name);
      form_data.append("parent", this.item.id);
      await this.addPage({body: form_data});
      this.hideInput();
      this.newSectionName = "";
    },
    async addNewDocument(name) {
      if (!name) return;
      let form_data = new FormData();
       let defaultDocument = {
          time: 1629209706671,
          blocks: [{id: "000", type: "header", data: { text: "Новый Документ", level: 2 } }],
          version: "2.20.0",
      };
      form_data.append("title", name);
      form_data.append("parent", this.item.id);
      form_data.append("data", JSON.stringify(defaultDocument));
      await this.addPage({body: form_data, document: true});
      this.hideInput()
      this.newSectionName = "";
    },
    async changeProjectName(name, id) {
      if (!name || !id) return;
      let form_data = new FormData();
      form_data.append("title", name);
      await this.changePage({ body: form_data, id: id});
      this.edit = false;
      this.hide();
    },
    async confirmDelete(state) {
      if(state) {
        await this.deletePage(this.item.id);
      }
      this.deleteConfirm = false
    },
  },
  mounted() {
    if(this.item.id === parseInt(this.$route.params.id)) {
     this.$emit('setActive')
    }
  }
};
</script>

<style scoped lang="scss">
.main-menu { 
    > .menu-item {
      padding-bottom: 15px;
      margin-bottom: 30px;
      margin-left: 0;
      border-bottom: 1px solid #DADADA;
        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
            margin: 0 auto;
        }
        .menu-item__header {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            color: #000000;
            &:hover {
                cursor: pointer;
                color: #0A467E;
                transition: .3s all;
            }

            & > a {
                font-family: inherit;
                font-style: inherit;
                font-weight: inherit;
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                text-decoration: none;
            }
        }

        .menu-item__document {
          font-weight: normal !important;
        }

        .menu-item {
          .menu-item__header {
            font-size: 17px;
            line-height: 21px;
            font-weight: 600;
          }

           .menu-item {
            .menu-item__header  {
              font-weight: normal;
            }
          }
        }
    }
}

.menu-item {
    margin-left: 45px;
    & .v-icon {
      color: currentColor;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        /* cursor: pointer; */
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #56565A;
        cursor: pointer;

        &:hover .project__menu {
          opacity: 0.7;
        }
    }

    &__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      & a {
        display: block;
        width: 100%;
        color: inherit;
        text-decoration: none;
      }
    }

    &__arrow {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
        cursor: pointer;
    }

    &__list {
        max-height: 0;
        overflow: hidden;
        transition: .3s;

        & .menu-item {
            margin-left: 45px;
        }
    }
}

.menu-item_current {
    > .menu-item__header {
        color: #0A467E;
        font-weight: 600;
    }
}

.menu-item_opened {
    > .menu-item {
        &__list {
            max-height: 5000px;
            overflow: visible;
            border-left: 1px solid #DADADA;
        }
        &__header {
            font-weight: 600;
            > .menu-item__name .menu-item__arrow {
                transform: rotate(180deg);
            }
        }
    }
}

.main-menu .menu-item_active {
    > .menu-item__header {
        font-weight: 600;
        color: #0A467E;
    }
}

.project__tools {
  margin-left: auto;
  position: relative;

  & > .project__menu {
    opacity: 0;
    cursor: pointer;
    transition: 0.3s;
    color: #000;
  }

  & .project__menu:hover {
    opacity: 1;
    color: #000;
  }

}

</style>